import React from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Newsletter from "../../components/newsletter.js";
import CTA from "../../components/cta.js";
import OfferDetails from "../../components/offerDetails.js";

class OfferPostTemplate extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 0, duration: 5, delay: 1 });
  }

  render() {
    //const siteTitle = this.props.data.site.siteMetadata.title
    //console.log(post)

    return (
      <Layout
        location={this.props.location}
        title="Rexer - Inżynieria środowiska"
      >
        <SEO
          title="Projektowanie"
          description="Wykonujemy projekty zarówno pojedynczych zakresów jak i całego pakietu - od instalacji wodnych, kanalizacyjnych i grzewczych, po wentylacje, klimatyzacje oraz instalacje technologiczne."
        />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>PROJEKTOWANIE</strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    INDYWIDUALNE <b>POTRZEBY</b>, DETALE, JAKOŚĆ PRZYSZŁEGO{" "}
                    <b>UŻYTKOWANIA </b>
                    JEST DLA NAS <b>PRIORYTETEM</b>
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  Wykonujemy projekty zarówno pojedynczych zakresów jak i całego
                  pakietu - od instalacji wodnych, kanalizacyjnych i grzewczych,
                  po wentylacje, klimatyzacje oraz instalacje technologiczne.
                </p>
                <a href="#main" className="button rexer-button">
                  Dowiedz się więcej
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "-150px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "550px",
                    opacity: "1"
                  }}
                  src="/img/hand-bulb.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-150px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div
                id="a1"
                className="column"
                style={{
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundImage: "url(/img/hero-bg-home.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center"
                }}
              >
                <div
                  style={{
                    marginTop: "50px",
                    margin: "0px",
                    padding: "0px",
                    alignItems: "flex-end",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img
                    src="/assets/projektowanie-icon.svg"
                    width="100%"
                    height="auto"
                    alt="Projektowanie"
                    style={{
                      minWidth: "100px",
                      maxWidth: "250px",
                      opacity: "1",
                      margin: "0px",
                      marginRight: "-50%"
                    }}
                  />
                </div>
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Na każdym etapie współpracy <b>służymy</b> fachowym{" "}
                  <b>doradztwem</b> i wsparciem.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#details">
                  Poznaj szczegółową ofertę
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px",
              textTransform: "uppercase"
            }}
          >
            OFERUJEMY <b>KOMPLEKSOWĄ OBSŁUGĘ</b> W BRANŻY INŻYNIERII ŚRODOWISKA
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            Chętnie dzielimy się naszą wiedzą i doświadczeniem, aby budować
            wzajemne relacje oraz optymalizować proces projektowania, a więc i
            samej budowy.
            <br />
            <br />
            Usługi te świadczymy zarówno w przypadku realizacji wykonywanych
            przez nas, jak i przez inne firmy. Inwestycje, które zdecydowano się
            objąć usługą commissioningu zdecydowanie zyskują na wartości,
            ponieważ dają poczucie pewności, że nad całością prowadzonych prac
            czuwali eksperci. Dzięki temu wszelkie wady były lub są eliminowane
            na najwcześniejszym możliwym etapie.
            <br />
            <br />
            Do każdego Klienta podchodzimy po partnersku oraz poza usługami
            projektowymi oferujemy również:
          </p>
        </div>

        <div id="render" style={{ padding: "2.5% 5%" }}>
          <div
            className="columns"
            style={{ maxWidth: "100%", margin: "5% 2%" }}
          >
            <div
              className="column is-half"
              style={{ padding: "3em", paddingLeft: "5%" }}
            >
              <h2 className="is-size-3">DORADZTWO TECHNICZNE</h2>
              <br />
              <p>
                Pomagamy ocenić przygotowany projekt budowlany, techniczny jak i
                wykonawczy, sugerując wprowadzenie ewentualnych usprawnień
                korzystnych dla Klienta.
                <br />
                <br />
              </p>
              {/*<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                <Link to="/kontakt#form">
                  <button className="button rexer-button is-size-6">Wyceń usługę</button>
                </Link>
              </div>*/}
            </div>
            <div
              className="column is-half"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start"
              }}
            >
              <img
                className="rexer-button blog-img"
                src="/img/doradztwo.jpg"
                alt="sponsoring"
                width="100%"
                style={{ transform: "scale(.85)" }}
              />
            </div>
          </div>

          <div
            className="columns mob-flow"
            style={{ maxWidth: "100%", margin: "1%" }}
          >
            <div
              className="column is-half"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start"
              }}
            >
              <img
                className="rexer-button blog-img"
                src="/img/audyt.jpg"
                alt="sponsoring"
                width="100%"
                style={{ transform: "scale(.85)" }}
              />
            </div>
            <div
              className="column is-half"
              style={{ padding: "3em", paddingLeft: "5%" }}
            >
              <h2 className="is-size-3">AUDYT PROJEKTÓW</h2>
              <br />
              <p>
                Jesteśmy w stanie przeprowadzić pełny audyt sprawności systemów
                wbudowanych na obiekcie. Po testach przedstawiamy kierunki
                poprawiające funkcjonalność układów.
                <br />
                <br />
              </p>
              {/*<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                <Link to="/kontakt#form">
                  <button className="button rexer-button is-size-6">Wyceń usługę</button>
                </Link>
              </div>*/}
            </div>
          </div>
          <div
            className="columns"
            style={{ maxWidth: "100%", margin: "5% 2%" }}
          >
            <div
              className="column is-half"
              style={{ padding: "3em", paddingLeft: "5%" }}
            >
              <h2 className="is-size-3">COMMISSIONING</h2>
              <br />
              <p>
                Wspieramy wiedzą i doświadczeniem naszej kadry każdy etap prac,
                aby efekt końcowy był zgodny z oczekiwaniami i bezawaryjnie
                służył przez wiele lat.
                <br />
                <br />
              </p>
              {/*<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
              <Link to="/kontakt#form">
                <button className="button rexer-button is-size-6">Wyceń usługę</button>
              </Link>
            </div>*/}
            </div>
            <div
              className="column is-half"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start"
              }}
            >
              <img
                className="rexer-button blog-img"
                src="/img/commisioning.jpg"
                alt="sponsoring"
                width="100%"
                style={{ transform: "scale(.85)" }}
              />
            </div>
          </div>
          <hr id="details" />
          <div
            id="oferta"
            className="section"
            style={{ display: "block", position: "relative" }}
          >
            <h2
              className="subtitle is-size-4"
              style={{
                borderLeft: "5px solid #00ff00",
                fontWeight: "600",
                textTransform: "uppercase",
                marginTop: "50px",
                paddingLeft: "10px"
              }}
            >
              Szczegółowy zakres naszej oferty
              <br />
            </h2>
            <br />
            <p>
              Chętnie dzielimy się naszą wiedzą i doświadczeniem, aby budować
              wzajemne relacje oraz optymalizować proces projektowania, a więc i
              samej budowy. Do każdego Klienta podchodzimy po partnersku oraz
              poza usługami projektowymi oferujemy również:
            </p>
          </div>
          <OfferDetails />
          <br />
          <br />
        </div>

        <Newsletter />
        <CTA />
      </Layout>
    );
  }
}

export default OfferPostTemplate;
